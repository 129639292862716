<template>
  <div>
    <b-card>
      <h5>เครดิตรวมคงเหลือของลูกค้าทั้งระบบ</h5>
      <b-overlay :show="loading" spinner-small>
        <h1 class="text-center mb-0 py-1 text-success">
          {{ gFormatNumberToCurrency(allData.customerCreditBalanceTotal) }}
        </h1>
      </b-overlay>
    </b-card>

    <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end mb-50">
      <p class="m-0 mb-50">เลือกเดือน-ปี</p>
      <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
        <b-overlay :show="dateSelectLoading || loading" spinner-small>
          <v-select
            v-model="dateSelected"
            :options="dateList"
            :clearable="false"
            :get-option-label="option => $date(option).format('MM-YYYY')"
            @input="onChangeDateSelect"
          />
        </b-overlay>
      </b-card>
    </div>

    <h5>สรุปยอดขายรูป</h5>
    <b-row cols="1" cols-sm="2">
      <b-col>
        <b-card class="mb-1">
          <h5>ยอดขาย</h5>
          <b-overlay :show="loading" spinner-small>
            <h3 class="text-center mb-0 py-1 text-success">
              {{ gFormatNumberToCurrency(allData.imageSale.total) }}
            </h3>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h5>ยอดรีฟัน</h5>
          <b-overlay :show="loading" spinner-small>
            <h3 class="text-center mb-0 py-1 text-danger">
              {{ gFormatNumberToCurrency(allData.imageSale.refund) }}
            </h3>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <h5>สรุปรายการซื้อเครดิตแต่ละแพลตฟอร์ม</h5>
    <b-overlay :show="loading">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h5 class="mb-0">รวมทั้งหมด</h5>
          <vue-apex-charts
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="customersPie.chartOptions"
            :series="pieSeriesTotalRevenueCredit"
          />
        </b-card-body>
        <b-row class="text-center mx-0">
          <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
            <small class="text-muted mb-0">
              ซื้อสำเร็จ
            </small>
            <h3 class="font-weight-bolder mb-0 text-success">
              {{ gFormatNumberToCurrency(allData.revenue.total.success) }}
            </h3>
          </b-col>

          <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
            <small class="text-muted mb-0">
              ซื้อไม่สำเร็จ
            </small>
            <h3 class="font-weight-bolder mb-0 text-danger">
              {{ gFormatNumberToCurrency(allData.revenue.total.failed) }}
            </h3>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-row cols="1" cols-md="3" class="mb-2">
      <b-col v-for="revenue in revenueList" :key="revenue.key">
        <b-card no-body class="mb-1">
          <b-card-body>
            <h4 class="mb-0 text-center">
              {{ revenue.label }}
            </h4>
          </b-card-body>

          <b-overlay :show="loading" spinner-small>
            <b-row class="text-center mx-0">
              <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
                <small class="text-muted mb-0">
                  ซื้อสำเร็จ
                </small>
                <h3 class="font-weight-bolder mb-0 text-success">
                  {{ gFormatNumberToCurrency(allData.revenue[revenue.key].success) }}
                </h3>
              </b-col>

              <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
                <small class="text-muted mb-0">
                  ซื้อไม่สำเร็จ
                </small>
                <h3 class="font-weight-bolder mb-0 text-danger">
                  {{ gFormatNumberToCurrency(allData.revenue[revenue.key].failed) }}
                </h3>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <h5>สรุปรายการปรับเครดิตโดยแอดมิน</h5>
    <b-row cols="1" cols-sm="2">
      <b-col>
        <b-card class="mb-1">
          <h5>เพิ่มเครดิต</h5>
          <b-overlay :show="loading" spinner-small>
            <h3 class="text-center mb-0 py-1">
              {{ gFormatNumberToCurrency(allData.admin.addCredit) }}
            </h3>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h5>ลดเครดิต</h5>
          <b-overlay :show="loading" spinner-small>
            <h3 class="text-center mb-0 py-1">
              {{ gFormatNumberToCurrency(allData.admin.reduceCredit) }}
            </h3>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'

export default {
  components: {
    VueApexCharts,
    vSelect,
  },
  data() {
    return {
      allData: {
        revenue: {
          ios: {
            success: 0,
            failed: 0,
          },
          android: {
            success: 0,
            failed: 0,
          },
          promptpay: {
            success: 0,
            failed: 0,
          },
          total: {
            success: 0,
            failed: 0,
          },
        },
        admin: {
          addCredit: 0,
          reduceCredit: 0,
        },
        customerCreditBalanceTotal: 0,
        customerImagePurchase: 0,
        imageSale: {
          total: 0,
          refund: 0,
        },
      },
      loading: false,
      revenueList: [
        { key: 'ios', label: 'Ios' },
        { key: 'android', label: 'Android' },
        { key: 'promptpay', label: 'PromptPay' },
      ],
      customersPie: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['ซื้อสำเร็จ', 'ซื้อไม่สำเร็จ'],
          dataLabels: {
            enabled: true,
            // formatter(val) {
            //   return `${Number(val).toFixed(2)} %`
            // },
          },
          legend: {
            show: false,
          },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.success, $themeColors.danger],
        },
      },
      dateList: [],
      dateSelected: this.$date(new Date()).format('YYYY-MM-01'),
      dateSelectLoading: false,
    }
  },

  computed: {
    pieSeriesTotalRevenueCredit() {
      const { success, failed } = this.allData.revenue.total
      return [success, failed]
    },
  },
  created() {
    this.fetchAllData()
    this.fetchAllDate()
  },
  methods: {
    async fetchAllDate() {
      this.dateSelectLoading = true
      const resp = await this.api.getV2('api/admin/report/fetch-date/all-credit').catch(() => null)
      this.dateSelectLoading = false
      if (!resp) return

      if (resp?.code === 200) {
        const formatList = [...resp.data].map(v => v.date)
        this.dateList = formatList
      }
    },
    async fetchAllData(date = null) {
      const dateFormat = this.$date(date ?? new Date()).format('YYYY-MM-01')

      this.loading = true
      const resp = await this.api.postV2('api/admin/report/all-credit', { date: dateFormat }, this).catch(() => null)
      this.loading = false
      if (!resp) return

      if (resp.code === 200) {
        this.allData = { ...resp.data }
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
    },
    onChangeDateSelect(value) {
      this.fetchAllData(value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
